import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

import { Card } from '../../components/on-boarding/Card';
import { ElevatedButton } from '../../components/buttons';
import { keycloak } from '../../keycloak';
import { ProducerLogo } from '../../components/icons/producer-logo';
import { globalConfig } from '../../configuration/config';

export const SSOPage = () => {
  const { alias } = useParams();

  const [space, setSpace] = useState<{ _id: string; name: string }>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const baseUrl = globalConfig.config.baseApiUrl.replace('/v1', '');
    axios.get(`${baseUrl}/public/spaces/${alias}`).then(({ data }) => {
      setSpace(data);
      setIsLoading(false);
    });
  }, [alias]);

  const handleSSOLogin = () => {
    if (!space) {
      return;
    }

    keycloak.init({}).then(() => {
      keycloak.login({
        idpHint: alias,
        redirectUri: `https://app.staging.the-producer.io/spaces/${space._id}`,
      });
    });
  };

  if (isLoading) {
    return <Page />;
  }

  if (!space) {
    return <Page />;
  }

  return (
    <Page>
      <Container>
        <Content>
          <ProducerLogo />

          <Card
            title={`Single sign-on to ${space.name}`}
            subtitle={`Authenticate your account by logging into ${space.name}’s single sign-on provider.`}
          >
            <ElevatedButton text="Continue" onClick={handleSSOLogin} />
          </Card>
        </Content>
      </Container>
    </Page>
  );
};

const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  height: 100%;
  background-color: var(--color-grayscale-eerie-black);
  padding-top: 4rem;
`;

const Container = styled.div`
  max-width: 37rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.2rem;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.4rem;
`;
